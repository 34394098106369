<template>
  <div >
      <el-image v-if="url"
                :src="url"
      ></el-image>
      <el-empty description="暂无数据" v-else></el-empty>
  </div>
</template>

<script>
import { getMemberCardByQrCode } from '@/api/partners'

export default {
  data() {
    return {
      url: null
    }
  },
  mounted() {
    this.getImg()
  },
  methods: {
    getImg() {
      getMemberCardByQrCode(this.$route.params).then((res) => {
        if (res.code === 200) {
          this.url = this.loadUrl(res.msg)
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
